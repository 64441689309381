import React, { useEffect, useContext, useState } from "react";
import DateFilter from "./DateFilter";
import StatusFilter from "./StatusFilter";
import ButtonModal from "./ButtonModal";
import PendingAlert from "./PendingAlert";
import { startOfDay, endOfDay } from "date-fns";
import { GlobalContext } from "../../../context/GlobalState";

const SearchForm = () => {
  const { DDCases, getInitialData } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const {
    filterByStatus,
    isActiveNA,
    isActiveNR,
    isActiveApplied,
    isActiveConfirmed,
    isActivePending,
  } = useContext(GlobalContext);
  useEffect(() => {
    if (DDCases.length > 0) {
      return;
    } else {
      getInitialData();
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <DateFilter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          isActiveNA={isActiveNA}
          isActiveNR={isActiveNR}
          isActiveApplied={isActiveApplied}
          isActiveConfirmed={isActiveConfirmed}
          isActivePending={isActivePending}
          DDCases={DDCases}
        />
        <PendingAlert />
        <StatusFilter
          filterByStatus={filterByStatus}
          isActiveNA={isActiveNA}
          isActiveNR={isActiveNR}
          isActiveApplied={isActiveApplied}
          isActiveConfirmed={isActiveConfirmed}
          isActivePending={isActivePending}
          DDCases={DDCases}
        />
        <ButtonModal />
      </div>
    </>
  );
};

export default SearchForm;
