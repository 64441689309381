import React, { useState, useContext, useMemo } from "react";
import format from "date-fns/format";
import XLSX from "xlsx";
import axios from "axios";
import sub from "date-fns/sub";
import { FiRefreshCw, FiArrowRight, FiDownload } from "react-icons/fi";
import { GlobalContext } from "../../../context/GlobalState";

const ButtonDownload = ({ DDCases }) => {
  const {
    role,
    isActiveNA,
    isActiveNR,
    isActiveApplied,
    isActiveConfirmed,
    isActivePending,
    showPreviousCases,
    pending,
    confirmed,
  } = useContext(GlobalContext);
  const [isDownloading, setIsDownloading] = useState(false);

  const data = useMemo(() => {
    if (showPreviousCases === true && role === "contable") {
      return pending;
    } else if (showPreviousCases === true && role === "admin") {
      return confirmed;
    } else {
      return DDCases.filter((e) => {
        if (
          isActivePending === true ||
          isActiveConfirmed === true ||
          isActiveApplied === true ||
          isActiveNA === true ||
          isActiveNR === true
        ) {
          return (
            (isActivePending === true && e.status === "pending") ||
            (isActiveConfirmed === true && e.status === "confirmed") ||
            (isActiveApplied === true && e.status === "applied") ||
            (isActiveNA === true && e.status === "N/A") ||
            (isActiveNR === true && e.status === "no_received")
          );
        } else {
          return e;
        }
      });
    }
  }, [
    DDCases,
    isActiveNA,
    isActiveNR,
    isActiveApplied,
    isActiveConfirmed,
    isActivePending,
    showPreviousCases,
  ]);

  const donwloadDataXLSX = async () => {
    const confirmedCases = data.map((key, index) => {
      return {
        Id: index + 1,
        Name: key.name,
        Link: `https://backoffice.ualett.com/user/${key.userId}`,
        Status: key.status,
        Amount: key.amount,
        Code: key.code,
        Installment: key.installment.toString(),
        CreatedDate: key.createdAt
          ? format(new Date(key.createdAt), "MM/dd/yyyy")
          : "N/a",
        Time: key.time,
        Moth: format(new Date(key.createdAt), "MMM"),
        Year: new Date(key.createdAt).getFullYear(),
        AppliedDate: key.applied_date
          ? format(new Date(key.applied_date), "MM/dd/yyyy")
          : "N/a",
        note: key.note,
      };
    });

    const ws = XLSX.utils.json_to_sheet(confirmedCases);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Applied");

    XLSX.writeFile(wb, "Data importada.xlsx");
    setTimeout(() => {
      setIsDownloading(false);
    }, 5000);
  };

  return (
    role === "overdue" && (
      <button
        style={{
          padding: ".3rem .5rem",
          marginLeft: "20px",
          display: "inline-block",
        }}
        disabled={isDownloading ? true : false}
        className="btn btn-primary"
        onClick={() => {
          setIsDownloading(true);
          donwloadDataXLSX();
        }}
      >
        <FiDownload size={19} />
      </button>
    )
  );
};

export default ButtonDownload;
