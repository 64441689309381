import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalState";
import Select from "react-select";

const StatusFilter = ({
  filterByStatus,
  isActiveNA,
  isActiveNR,
  isActiveApplied,
  isActiveConfirmed,
  isActivePending,
  DDCases,
}) => {
  const handleClick = (status) => {
    filterByStatus(status);
  };

  const options = [
    { value: "N/A", label: "N/A", color: "#C0392B" },
    { value: "no_received", label: "no_received", color: "#4c2882" },
    { value: "pending", label: "pending", color: "#4c2882" },
    { value: "confirmed", label: "confirmed", color: "#4c2882" },
    { value: "applied", label: "applied", color: "#4c2882" },
  ];

  return (
    // <Select
    //   isClearable
    //   className={"select"}
    //   placeholder="Show..."
    //   styles={{ width: "200px" }}
    //   // isDisabled={
    //   //   notSelectedFilter !== null && notSelectedFilter.length
    //   //     ? true
    //   //     : false
    //   // }
    //   // value={selectedFilter}
    //   // onChange={(value) => {
    //   //   setSelectedFilter(value);
    //   //   setAlteredFilters(true);
    //   // }}
    //   options={options.sort()}
    //   isMulti
    // />

    // <select className="form-control" style={{ width: "200px" }}>
    //   {options.map((option) => (
    //     <option value={option.value}>{option.value}</option>
    //   ))}
    // </select>
    <div className="d-flex justify-content-between align-items-center">
      <span className="mr-2">N/A:</span>
      <button
        className="btn btn-outline-dark px-4 mr-2"
        style={{
          backgroundColor: isActiveNA === true ? "#343a40" : "",
          color: isActiveNA === true ? "white" : "",
        }}
        onClick={() => handleClick("N/A")}
      >
        {DDCases?.filter((e) => e.status === "N/A").length}
      </button>
      <span className="mr-2">NR:</span>
      <button
        className="btn btn-outline-danger px-4 mr-2"
        style={{
          backgroundColor: isActiveNR === true ? "#d9534f" : "",
          color: isActiveNR === true ? "white" : "",
        }}
        onClick={() => handleClick("no_received")}
      >
        {DDCases?.filter((e) => e.status === "no_received").length}
      </button>
      <span className="mr-2">P:</span>
      <button
        className="btn btn-outline-warning px-4 mr-2"
        style={{
          backgroundColor: isActivePending === true ? "#f0ad4e" : "",
          color: isActivePending === true ? "white" : "",
        }}
        onClick={() => handleClick("pending")}
      >
        {DDCases?.filter((e) => e.status === "pending").length}
      </button>
      <span className="mr-2">C:</span>
      <button
        className="btn btn-outline-success px-4 mr-2"
        style={{
          backgroundColor: isActiveConfirmed === true ? "#02b875" : "",
          color: isActiveConfirmed === true ? "white" : "",
        }}
        onClick={() => handleClick("confirmed")}
      >
        {" "}
        {DDCases?.filter((e) => e.status === "confirmed").length}
      </button>
      <span className="mr-2">A:</span>
      <button
        className="btn btn-outline-info px-4 mr-2"
        style={{
          backgroundColor: isActiveApplied === true ? "#17a2b8" : "",
          color: isActiveApplied === true ? "white" : "",
        }}
        onClick={() => handleClick("applied")}
      >
        {" "}
        {DDCases?.filter((e) => e.status === "applied").length}
      </button>
    </div>
  );
};

export default StatusFilter;
